.technology {
    height : 100%;
    padding: 2% 10%;
    background-color: #eaeaea;
}

.technology .title {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    text-anchor: revert;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.technology .title div {
    color: #091930;
}

.technology > .techName {
    width: 100vw;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.technology ul {
    font-weight: 500 !important;
    font-family: 'Saira Condensed';
}

.technology ul.cloud {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    line-height: 3.75rem;
    width: 100%;
}

.technology ul.cloud a {
    --size: 4;
    --color: #a33;
    color: var(--color);
    font-size: calc(var(--size) * 0.25rem + 0.5rem);
    display: block;
    padding: 0.125rem 0.85rem;
    position: relative;
    text-decoration: none;
}

.technology ul.cloud a[data-weight="1"] { --size: 1; }
.technology ul.cloud a[data-weight="2"] { --size: 2; }
.technology ul.cloud a[data-weight="3"] { --size: 3; }
.technology ul.cloud a[data-weight="4"] { --size: 4; }
.technology ul.cloud a[data-weight="5"] { --size: 6; }
.technology ul.cloud a[data-weight="6"] { --size: 8; }
.technology ul.cloud a[data-weight="7"] { --size: 10; }
.technology ul.cloud a[data-weight="8"] { --size: 13; }
.technology ul.cloud a[data-weight="9"] { --size: 16; }

.technology ul[data-show-value] a::after {
    content: " (" attr(data-weight) ")";
    font-size: 1rem;
}

.technology ul.cloud li:nth-child(2n+1) a { --color: #181; }
.technology ul.cloud li:nth-child(3n+1) a { --color: #33a; }
.technology ul.cloud li:nth-child(4n+1) a { --color: #c38; }

.technology ul.cloud a:focus {
    outline: 1px dashed;
}

.technology ul.cloud a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background: var(--color);
    transform: translate(-50%, 0);
    opacity: 0.15;
    transition: width 0.25s;
}

.technology ul.cloud a:focus::before,
.technology ul.cloud a:hover::before {
    width: 100%;
}

@media (prefers-reduced-motion) {
    .technology ul.cloud * {
        transition: none !important;
    }
}



  